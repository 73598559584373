<template>
  <v-dialog v-model="dialogVisible" width="400" scrollable>
    <template v-slot:activator="{ on }">
      <TermItem v-bind="$attrs" v-on="on" :value="modelValue" v-if="listItem" />
      <v-btn
        v-else
        v-bind="$attrs"
        v-on="on"
        :color="modelValue.current ? 'success' : ''"
      >
        {{
          $vuetify.breakpoint.mobile
            ? modelValue.shortText
            : modelValue.fullText
        }}
        <v-icon right>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-system-bar window
        >Semester wählen<v-spacer /><v-icon @click="close"
          >mdi-close</v-icon
        ></v-system-bar
      >
      <v-card-text class="pa-0">
        <v-list>
          <v-list-item-group v-model="modelValue" mandatory>
            <TermItem v-for="item in items" :key="item.id" :value="item" />
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn block text @click="close">Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { today } from "common/utils/date.js";
import TermItem from "common/components/TermItem.vue";

export default {
  name: "TermPicker",
  components: { TermItem },
  props: {
    listItem: {
      type: Boolean,
      default: false,
    },
    range: null, //  array
  },
  data() {
    return {
      dialogVisible: false,
      items: [],
      modelValue: {},
    };
  },
  watch: {
    modelValue() {
      this.$emit("input", this.modelValue);
      window.localStorage.setItem("TermPicker", this.modelValue.id);
      this.close();
    },
  },
  async created() {
    const now = today();
    if (!this.range) {
      this.items = await this.apiList({
        resource: "common/term",
      });
    } else {
      if (Array.isArray(this.range) && this.range.length == 2) {
        this.items = await this.apiList({
          resource: "common/term",
          query: `offsetpast=${this.range[0]}&offsetfuture=${this.range[1]}`,
        });
      }
    }

    // select previously selected item
    const selectedId = Number.parseInt(
      window.localStorage.getItem("TermPicker")
    );
    this.modelValue = this.items.find((item) => item.id === selectedId);
    // select current term
    if (!this.modelValue) {
      this.modelValue = this.items.find(
        (item) => item.startDate <= now && now <= item.endDate
      );
    }
  },
  methods: {
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>
