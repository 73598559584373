<template>
  <v-list-item
    v-bind="$attrs"
    v-on="{ ...$listeners }"
    :color="value.current ? 'success' : 'primary'"
    :value="value"
    v-if="value"
  >
    <v-list-item-content>
      <v-list-item-title>
        {{ value.fullText }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ formatDatespan(value.startDate, value.endDate, true) }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action-text v-if="value.current" class="success--text">
      aktuell
    </v-list-item-action-text>
  </v-list-item>
</template>
<script>
import { formatDatespan } from "common/utils/date.js";

export default {
  name: "TermValue",
  props: {
    value: null,
  },
  methods: {
    formatDatespan: formatDatespan,
  },
};
</script>
