<template>
  <Calendar
    @change="change"
    :exams="exams"
    :holidays="holidays"
    name="schoolClassExams"
    :allowedViews="['term']"
    :loading="loading"
  >
    <v-spacer></v-spacer>
    <ReportButton
      color="success"
      text
      resource="report/exams"
      :parameters="{ type: 'class', id: schoolClass.id }"
      >XLS</ReportButton
    >
    <v-btn
      color="info"
      text
      @click="apiIcal('schoolclassexams', schoolClass.id)"
      ><v-icon left>mdi-download</v-icon>ical</v-btn
    >
  </Calendar>
</template>

<script>
import Calendar from "@/components/Calendar.vue";
import ReportButton from "@/components/ReportButton";

export default {
  name: "Register",
  components: { Calendar, ReportButton },
  props: ["schoolClass"],
  data() {
    return {
      loading: false,
      exams: [],
      holidays: [],
      start: null,
      end: null,
    };
  },
  watch: {
    schoolClass() {
      this.fetchData();
    },
  },
  methods: {
    async change(start, end) {
      this.start = start;
      this.end = end;
      this.fetchData();
    },
    async fetchData() {
      if (
        !this.start ||
        !this.end ||
        !this.schoolClass ||
        !this.schoolClass.id
      ) {
        return;
      }
      this.loading = true;
      this.holidays = await this.apiList({
        resource: "common/holiday",
        query: `startDate=${this.start}&endDate=${this.end}`,
      });
      this.exams = (
        await this.apiList({
          resource: "register/note",
          query: `type=exam&startDate=${this.start}&endDate=${this.end}&schoolClass=${this.schoolClass.id}`,
        })
      ).filter((el) => el.plannedExam);

      this.loading = false;
    },
  },
  created() {
    //this.fetchData();
  },
};
</script>
